$primary_color: #002136;
$secondary_color: #4C83B4;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #111111;
}

html {
  scroll-behavior: smooth;
  background-color: #F6F6F6;
  font-size: 1rem;
}

.md-button {
  background-color: $primary_color;
  border: none;
  padding: 10px;
  border-radius: 3px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1em;
  color: white;
  cursor: pointer;
}

.md-button:hover {
  box-shadow: 0 1px 2px #777;
}

.menu-button {
  position: relative;
  animation: menu-appear-animation .3s ease-out;
}

img, video {
  max-width: 100%;
  height: auto;
}

.gallery img, .gallery video {
  background-color: #EFEFEF;
  box-shadow: 0 1px 2px 0 #666666;
}

#appbar {
  position: fixed;
  top: 0;
  min-height: 2rem;
  width: calc(100% - 1.2rem);
  display: flex;
  padding: .6rem;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  background-color: $primary_color;
  color: white;
}

#appbar .material-icons {
  font-size: 2rem;
  cursor: pointer;
  color: white;
}

#appbar div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#appbar a {
  text-decoration: underline;
  cursor: pointer;
  margin-right: 1rem;
  font-size: 1rem;
  padding: .4rem;
  border-radius: 3px;
}

#appbar a.active {
  background-color: white;
  color: $primary_color;
}

.material-icons {
  cursor: default;
}

a .material-icons {
  cursor: pointer;
}

#nav {
  position: fixed;
  right: -18rem;
  top: 3.2rem;
  height: calc(100vh - 3.2rem);
  width: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 5px 0 #111111;
  background-color: #E0E0E0;
  transition: .5s;
}

#nav.open {
  right: 0;
}

#nav ul {
  margin: 0;
  padding: .3rem;
  list-style-type: none;
}

#nav ul li a {
  margin: .2rem;
  padding: .7rem .3rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  color: #111111;
}

#nav ul li a .material-icons {
  font-size: 1.65rem;
}

#nav ul li a span {
  margin-right: 1.5rem;
}

#nav #contact-info {
  margin: 1rem .5rem;
  padding-top: 1rem;
}

#nav #contact-info li > * {
  font-size: .8rem;
  font-weight: normal;
  padding: 0;
  margin: .1rem;
}

#nav #contact-info li {
  margin: 1.3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#nav #contact-info li .icon {
  height: 1.6rem;
  width: auto;
  padding: 0;
  font-size: 1.6rem;
}

#content-hider {
  position: fixed;
  top: 3.2rem;
  height: calc(100vh - 3.2rem);
  width: 0;
  background-color: rgba(10, 10, 10, .5);
}

#content-hider.open {
  width: 100%;
}

#content {
  position: relative;
  margin: 0;
  padding: .001rem .4rem;
  top: 4.2rem;
  min-height: calc(100vh - 5.2rem);
}

@media screen and (min-width: 333px) {
  #content {
    top: 3.2rem;
  }
}

#content > article {
  margin: 2rem 0;
}

#content > article:first-child {
  margin-top: 0;
}

.link {
  position: relative;
  top: -3.5rem;
}

.p {
  margin: 1rem .5rem;
  text-align: justify;
  overflow: auto;
}

#about-me img {
  height: auto;
  max-width: 100%;
}

#about-me > div > div:first-child {
  max-width: 500px;
}

#competences-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#competences-list > li {
  margin: .6rem .3rem;
  box-shadow: 0 1px 2px 0 #776666;
  border-radius: 1px;
  overflow-y: hidden;
  background-color: white;
}

#competences-list li > div:first-child {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  background-color: #E0E0E0;
  cursor: pointer;
}

#competences-list li > div:first-child .material-icons {
  cursor: pointer;
}

#competences-list li > div:last-child {
  max-height: 0;
  margin: 0;
  padding: 0 1rem;
  list-style-type: none;
  background-color: #FFFFFF;
  transition: max-height .14s ease-in-out, padding .4s ease;
}

#competences-list li > div.open:last-child {
  max-height: 20rem;
  padding: 1rem 1rem;
}

#competences-list li ul {
  margin: 0;
  padding: 0;
}

#competences-list li p, #competences-list li h3 {
  margin: 0 0 1rem 0;
  padding: 0;
}

#competences-list ul li {
  position: relative;
  left: -100rem;
  max-height: 0;
  display: inline-block;
  margin: 0 .3rem;
  padding: 0 .8rem;
  overflow: hidden;
  background-color: #E0E0E0;
  border-radius: 5rem;
  transition: .4s ease;
  text-align: center;
  font-size: .8rem;
}

#competences-list li > .open li {
  left: 0;
  max-height: 2rem;
  margin: .3rem;
  padding: .8rem;
}

#projects-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 1rem;
}

#projects-list video, #projects-list img {
  height: 100%;
  max-width: 100%;
}

#projects-list ul {
  padding: 0;
}

#projects-list li {
  a {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 #776666;
    border-radius: 3px;
    overflow: hidden;
    color: #111111;
    text-decoration: none;
    transition: .2s;
    cursor: pointer;
    position: relative;

    &:hover {
      transform: scale(1.03);
    }

    .interactive {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $secondary_color;
      padding: 5px;
      background-color: #F6F6F6;
      border-radius: 50%;
      box-shadow: 1px 2px 4px #999;
    }

    .img {
      width: 100%;
      height: auto;
      padding: 28.125% 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .img-container {
      position: relative;
    }

    video {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
    }

    h3 {
      min-width: calc(240px - 2rem);
      width: 100%;
      margin: 0;
      padding: 1rem;
      background-color: #EFEFEF;
    }
  }
}

.print-button {
  position: absolute;
  top: .5rem;
  right: .5rem;
  padding: .5rem;
  background-color: $secondary_color;
  border-radius: 5px;
  border: none;
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
  cursor: pointer;
  box-shadow: 1px 1px 4px -5px #666;
  transition: .3s ease;
}

.print-button:hover {
  box-shadow: 1px 1px 7px -1px #666;
}

#resume, #resume a {
  color: black;
}

#resume {
  font-family: "Gill Sans Nova";
}

#resume .header {
  margin: .5rem;
  display: grid;
  grid-template-columns: 30% 70%;
  width: 400px;
  height: 120px;
}

#resume .header #header-details {
  display: flex;
  flex-direction: row;
}

#resume .header img {
  border-radius: 50%;
}

#resume .header div {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#resume .header p {
  margin: 0;
  display: flex;
  flex-direction: column;
}

#resume .header p span:last-child {
  font-style: italic;
}

#resume .main > section {
  margin: .2rem 0;
  border-bottom: 1px solid #999;
}

#resume .main > section:last-child {
  border-bottom: none;
}

#resume .main section h3 {
  margin: 0;
}

#resume .main ul {
  margin: .3rem;
  padding: 0 2rem;
}

#resume .main ul li ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

#resume .main ul li span {
  margin-right: .6rem;
}

#resume .main .date {
  font-style: italic;
}

#resume .institution {
  font-weight: bold;
}

#resume .extra {
  display: block;
}

#resume h3 {
  margin: .5rem 0;
  font-size: 1.3rem;
}

#resume h4 {
  margin: .2rem 1rem .2rem 0;
  display: inline-block;
  font-size: 1.1rem;
}

#resume .main ul li ul li {
  padding: .4rem;
  margin: .2rem;
  background-color: $primary_color;
  border-radius: 5px;
  font-size: .9rem;
  color: white;
}

#resume .main section ul {
  padding: 0 0 0 1rem;
}

#resume .main section > ul li {
  margin: .2rem 0;
}

#resume .main section p {
  margin: .4rem 0;
}

#resume .side-bar a {
  color: #111111;
}

#resume .side-bar section > ul {
  list-style-type: none;
  margin: .3rem;
  padding: 0;
}

#resume .side-bar section > ul > li {
  margin: .5rem 0;
}

#resume .side-bar section ul li .level {
  padding: 0;
  display: flex;
  list-style-type: none;
}

#resume .side-bar section ul li .level li {
  height: .5rem;
  width: .5rem;
  margin: .2rem;
  border: 1px solid $primary_color;
  border-radius: 50%;
}

#resume .side-bar section ul li .level li.filled {
  background-color: $primary_color;
}

.snake-ai {
  .stepper-show {
    position: relative;

    & > * {
      position: absolute;
      top: 0;
      left: -105%;
      width: 100%;
      opacity: 0;
      height: 0;
      transition: .3s ease opacity, .3s ease left;
      padding: 1rem 0 4rem 0;
      pointer-events: none;
    }

    .active {
      opacity: 1;
      height: auto;
      left: 0;
      pointer-events: auto;
    }

    .right {
      left: 105%
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 #776666;
    border: none;
    padding: 0;
    border-radius: 3px;
    overflow: hidden;
    color: #111111;
    text-decoration: none;
    transition: .2s;
    cursor: pointer;
    position: relative;
    text-align: left;

    p {
      padding: 0 1rem;
    }

    &:hover {
      transform: scale(1.03);
    }

    .img {
      width: 100%;
      height: auto;
      padding: 28.125% 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .img-container {
      position: relative;
      width: 100%;
    }

    video {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
    }

    h3 {
      text-align: left;
      min-width: calc(240px - 2rem);
      width: 100%;
      margin: 0;
      padding: 1rem;
      background-color: #EFEFEF;
    }
  }

  .type, .senses {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 1rem;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .setUp {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
  }

  .range-slider {
    position: relative;

    label {
      position: absolute;
      top: -15px;
      font-weight: bold;
    }
  }

  .snake-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .range-slider {
      width: calc(100% - 8rem);
    }

    .play-buttons {
      button {
        border: none;
        border-radius: 50%;
        padding: 2rem;
        cursor: pointer;
        box-shadow: 1px 1px 3px 0 #aaa;
        transition: .2s ease box-shadow;

        &.pause {
          background-color: $secondary_color;
          color: $primary_color;
        }

        &.play {
          background-color: $primary_color;
          color: $secondary_color;
        }

        &:hover {
          box-shadow: 1px 1px 6px 2px #aaa;
        }
      }
    }
  }

  .network {

    .layer-buttons {
      display: flex;
      justify-content: center;

      button {
        margin: 1rem;
      }
    }

    .the-network {
      display: flex;
      justify-content: space-around;

      .layer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .node {
          width: 50px;
          height: 50px;
          background-color: #ccc;
          margin: 10px;
        }

        &.input .node {
          background-color: $secondary_color;
        }

        &.output .node {
          background-color: $primary_color;
        }
      }
    }
  }

  .game {
    svg {
      background-color: #FFFFFF;

      .snake {
        fill: $secondary_color;

        &.head {
          fill: $primary_color;
        }
      }

      .food {
        fill: #40a0a0;
      }
    }
  }

  .snake-games {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 1rem;
    justify-content: center;

    .game {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: #FFFFFF;
      box-shadow: 0 1px 2px 0 #776666;
      border-radius: 3px;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50% 0;
        font-weight: bold;
        font-size: 1.3rem;
        color: $primary_color;

        background-color: $secondary_color;
        opacity: 0.8;

        animation: .3s ease-in fade-in;
      }

      div:first-child {
        width: 100%;
        height: 0;
        padding: 50% 0;
      }

      .best {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        font-weight: bold;

        span:last-child {
          border-radius: 50%;
          width: 4rem;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $secondary_color;
          margin-bottom: .15rem;
        }
      }

      .info {
        margin: 0;
        padding: 1rem;
        background-color: #EFEFEF;

        .score {
          span:first-child {
            margin-right: 5px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #appbar .menu-button {
    display: none;
  }

  #nav {
    right: 0;
    width: 18rem;
  }

  #content-hider {
    display: none;
  }

  #content {
    padding: 1rem;
    max-width: calc(100% - 20rem);
  }
}

@media screen and (min-width: 831px) {
  #projects-list {
    .two-cols {
      grid-area: span 2 / span 2;

      a:hover {
        transform: scale(1.015);
      }

      .img {
        padding: 28.125% 0;
      }
    }
  }
  .snake-ai {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }


}

.slick-prev:before, .slick-next:before {
  color: $secondary_color !important;
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide > div {
  padding: 0 5px;
}


.tile {
  display: grid;
  padding: 1px;
}

.unused-tiles {
  display: grid;
  grid-template-columns: repeat(auto-fit, 50px);
  grid-gap: .5rem;
  justify-content: center;
}

.unused-tiles div {
  display: grid;
  background: rgb(250, 220, 150);
  font-weight: 700;
  font-size: 1.5rem;
  border-radius: 10%;
  color: black;
}

.unused-tiles div::before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.unused-tiles div::before, .unused-tiles div div {
  grid-area: 1 / 1 / 2 / 2;
}

.unused-tiles div div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile::before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.tile::before, .tile input {
  grid-area: 1 / 1 / 2 / 2;
}

.tile input {
  width: 100%;
  padding: 0;
  margin: 0;
  background: rgb(200, 200, 200);
  border: none;
  text-align: center;
}

.unused-tile, .tile input {
  font-size: 1rem;
}

.line {
  display: grid;
}

.l2 input {
  background: rgb(60, 190, 250);
}

.w2 input {
  background: rgb(255, 150, 150);
}

.l3 input {
  background: rgb(10, 90, 180);
}

.w3 input {
  background: rgb(250, 0, 0);
}

.filled input {
  background: rgb(250, 220, 150);
  font-weight: 700;
  border-radius: 10%;
  color: black;
}

@media screen and (min-width: 1088px) {
  .tile {
    padding: 3px;
  }

  #about-me > div {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem;
  }

  #competences-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  #competences-list li > div:last-child {
    max-height: 40rem;
    padding: 1rem;
  }

  #competences-list li > div:first-child {
    min-height: 1.4rem;
    background-color: $primary_color;
    color: white;
    cursor: auto;
  }

  #competences-list ul li {
    left: 0;
    max-height: 2rem;
    margin: .3rem;
    padding: .8rem;
  }

  #competences-list li > div:first-child .material-icons {
    display: none;
  }

  #projects-list {
    grid-gap: 2rem;

    .two-cols {
      a:hover {
        transform: scale(1.05);
      }

      .img {
        padding: calc((100% - 3rem) * 0.28125 + 3rem) 0;
      }
    }

    li {
      margin: 0;

      a {
        &:hover {
          transform: scale(1.1);
        }

        .interactive {
          top: 10px;
          right: 10px;
        }
      }
    }
  }

  #content > section:last-child {
    margin: 3rem 0 0 0;
    padding: 2rem 2rem 0 2rem;
    border-top: 1px solid #666666;
  }

  #content > section:last-child h2 {
    display: none;
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
}

@media screen and (min-width: 1120px) {
  .hide-big {
    display: none;
  }

  #content {
    max-width: 800px;
    margin: 0 calc((100% - 20rem - 800px) / 2);
  }
}

@media print, screen and (min-width: 1088px) {
  #resume {
    display: grid;
    grid-template-columns: 71% 27%;
    grid-column-gap: 2%;
  }

  #resume .header {
    margin: 0;
  }

  #resume .main {
    margin-left: 1rem;
    grid-column: 1;
    grid-row: 2;
  }

  #resume .side-bar {
    grid-column: 2;
    grid-row: 2;
  }

  #resume .main > section:last-child {
    border-bottom: none;
  }
}

@media print {
  @page {
    size: A4;
    margin: 10mm;
  }

  html {
    background-color: white;
    font-size: .76rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  #appbar, #nav {
    display: none;
  }

  #content {
    padding: 0;
    top: 0;
  }

  .hide-in-print {
    display: none;
  }

  @supports (-moz-appearance:none) {
    html {
      margin: 10mm 20mm;
    }

    #resume .header {
      height: 85px;
      grid-template-columns: 21.25% 78.75%;
    }
  }

  #resume .main ul li ul li {
    color: transparent;
    text-shadow: 0 0 0 white;
  }
}

@keyframes menu-appear-animation {
  0% {
    transform: rotate(180deg);
    top: 0
  }

  50% {
    top: -7px;
  }

  100% {
    transform: rotate(0);
    top: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}
